import { Center } from "@chakra-ui/react";
import { CY_BROWN } from './utils';

const TopText =  ({text, height, color }) => {
  
  return (
    <Center 
      fontSize='2.5rem'
      h={height} 
      fontWeight='light' 
      letterSpacing='0.5em'
      color={color ? color : CY_BROWN}
      >
      {text}
    </Center>
  )
}

export default TopText;