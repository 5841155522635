import { Button, Stack, HStack, Flex } from "@chakra-ui/react";
import { CY_GRAY_BG } from "../common/utils";
import TopText from "../common/TopText";
import StoreItemCard from "./StoreItemCard";
import { shopItems, SHOP_MORE_LINK } from "../data/shopItems";
import { CY_BROWN, externalNavigate } from './../common/utils';

const StorePage = () => {

  return (  

    <Flex id='STORE' bg={CY_GRAY_BG} w='100%' justifyContent='center'padding='2em'>  
      <Stack>
        <TopText text="STORE" height='3em' width='30em' />
        <HStack wrap='wrap' justifyContent='center'>
          {shopItems && shopItems.map((shopItem, index) => {
            return <StoreItemCard 
                    key={index}
                    itemImage={shopItem.image} 
                    itemName={shopItem.name} 
                    price={shopItem.price}
                    shopLink={shopItem.link} />   
          }
        )}
        </HStack>
        <HStack wrap='wrap' justifyContent='center'>   
          <Button borderRadius='10em' 
                  color={CY_BROWN}
                  bg='#FFFFFF'
                  w='10em'
                  onClick={() => externalNavigate(SHOP_MORE_LINK, true)}
                  >
            shop now
          </Button>
        </HStack>
      </Stack>
  </Flex>
  )
}

export default StorePage;
