import { HStack } from "@chakra-ui/react";
import SocialButton from "./SocialButton";
import { SOCIALS } from "./utils";

const SocialStack = ({ color, bgColor }) => {
  return (
    <HStack wrap='wrap' justifyContent='center'>
      <SocialButton social={SOCIALS.Instagram} color={color} bgColor={bgColor} />
      <SocialButton social={SOCIALS.TikTok} color={color} bgColor={bgColor} />
      <SocialButton social={SOCIALS.Snapchat} color={color} bgColor={bgColor} />
      <SocialButton social={SOCIALS.Twitter} color={color} bgColor={bgColor} />
      <SocialButton social={SOCIALS.Facebook} color={color} bgColor={bgColor} />
      <SocialButton social={SOCIALS.YouTube} color={color} bgColor={bgColor} />
    </HStack>
  )
}

export default SocialStack;