import { Box, Center, Flex, Img, Hide, Spacer, Stack, IconButton, Text, Image } from "@chakra-ui/react";
// import chrisYoungImg from '%PUBLIC_URL%/052223_ChrisYoung_08_1869.jpg';
// import { TbCircleArrowLeft, TbCircleArrowRight } from 'react-icons/tb';
import { CY_GRAY_BG } from "../common/utils";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { featuredSong } from "../data/songs";
import { CY_BROWN, SMALL_SCREEN_BREAKPOINT } from './../common/utils';
import backgroundImage from '../images/background.jpg';

const MainPage = ({nextPage, prevPage}) => {
  return <Stack  bg={CY_GRAY_BG}>
    <Flex width='100%' gap={2} >
      <Spacer />
      <Flex 
      // bgImage={chrisYoungImg} 
      className="main-page"
      bgSize='contain' bgPosition='top' bgRepeat={'no-repeat'}
            id='MAIN' width='90%' borderRadius='6px'
          >
            <Image src={backgroundImage} width={'100%'} />
      </Flex>
      <Spacer />
    </Flex>
    <Center>
      <Flex transform='translateY(-1.5em)' flexWrap='none' justifyContent='center'
            w='70%' bg='white' 
      >
        <Hide breakpoint={SMALL_SCREEN_BREAKPOINT}> 
          <Img  
            w='6em' h='6em'
            src={featuredSong.image}
            alt='Looking For You'
          />
        </Hide>
        <Hide breakpoint={SMALL_SCREEN_BREAKPOINT}> 
          <Stack p={4} w='15em' h='6em' lineHeight='.8em' justifyContent='center' color={CY_BROWN} >
            <Text fontSize='.75rem' fontWeight='bold'>
              {featuredSong.artist.toUpperCase()}
            </Text>
            <Text fontSize='.75rem'>
              {featuredSong.name}
            </Text>
          </Stack>
        </Hide>
        <AudioPlayer 
          src={featuredSong.url}
          showSkipControls
          showJumpControls={false}
          onPlay={e => console.log("onPlay")}
          onClickNext={() => alert('next song')}
          borderRadius='6px'
        />
      </Flex>
    </Center>
  </Stack>
}

export default MainPage;
