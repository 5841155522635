export const WATCH_MORE_LINK = 'https://www.youtube.com/channel/UCkfj9FmVIPO9KOe3DwPPdBA';

export const videos= [
  {
    "name" : "Young Love & Saturday Nights",
    "link" : "https://www.youtube.com/embed/XN972Dmm9KU",
  },
  { 
    "name" : "Looking For You",
    "link" : "https://www.youtube.com/embed/3BSgH5mriZI",
  },
  {
    "name" : "At the End of a Bar",
    "link" : "https://www.youtube.com/embed/YTkD9tWUpD8",
  },
]
