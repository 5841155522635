import { Button } from "@chakra-ui/react";
import { CY_GRAY_BG } from "./utils";

const HeaderButton = ({ buttonText, onClick, navigateTag, color }) => {

    return (
      <Button 
        fontSize='.75rem' 
        minWidth='.7em'
        fontWeight='bold'
        color={color}
        padding='4px'
        bg={CY_GRAY_BG} 
        onClick={() => {onClick(navigateTag)}}
      >{buttonText}
      </Button>
    );
  }

export default HeaderButton;
