import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  Link
} from '@chakra-ui/react'
import { BOOKING_EMAIL_ADDR, MANAGEMENT_EMAIL_ADDR } from "../../data/links";
import { CY_BROWN, CY_GRAY_TEXT } from '../utils';

const ContactUsModal = ({ isOpen, onClose }) => {

    return <Modal onClose={onClose} size={'xl'} isOpen={isOpen} scrollBehavior={'inside'}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Contact Us</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text>
                    <b>For Booking:</b> <Link color={CY_GRAY_TEXT} href={`mailto: ${BOOKING_EMAIL_ADDR}`} isExternal>{BOOKING_EMAIL_ADDR}</Link>
                </Text>
                <Text>
                    <b>For Management:</b> <Link color={CY_GRAY_TEXT} href={`mailto: ${MANAGEMENT_EMAIL_ADDR}`} isExternal>{MANAGEMENT_EMAIL_ADDR}</Link>
                </Text>
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose}>Close</Button>
            </ModalFooter>
        </ModalContent>
      </Modal>
}

export default ContactUsModal;