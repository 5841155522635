import { useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import PageFooter from './Footer';
import PageHeader from './Header';
import MainPage from './MainPage'
import ListenPage from './ListenPage';
import WatchPage from './WatchPage';
import TourPage from './TourPage';
import StorePage from './StorePage';
import { PAGES, externalNavigate } from "../common/utils";
import '../styles/App.css';
import { BrowserRouter } from "react-router-dom";
import PrivacyPolicyModal from "../common/modals/PrivacyPolicyModal";
import TermsOfUseModal from '../common/modals/TermsOfUseModal';
import ContactUsModal from "../common/modals/ContactUsModal";

function App() {
  const maxPages = PAGES.length;  
  const [page, setPage] = useState(0);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);

  const nextPage = () => {
    const newPage = page + 1;
    newPage >= maxPages ? setPage(0) : setPage(newPage);
  }

  const prevPage = () => {
    const newPage = page - 1;
    newPage < 0 ? setPage(maxPages - 1) : setPage(newPage);
  }

  const scrollToPage = (pageName) => {
    if (PAGES.includes(pageName)) {
      const element = document.getElementById(pageName);
      element.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  }

  const handlePrivacyClick = () => {
    setShowPrivacyPolicy(true);
  }

  const handleTermsOfUseClick = () => {
    setShowTermsOfUse(true)
  }

  const handleContactUs = () => {
    setShowContactUs(true);
  }

  return (
    <BrowserRouter>
      <ChakraProvider>
        <PrivacyPolicyModal isOpen={showPrivacyPolicy} onClose={() => setShowPrivacyPolicy(false)} />
        <TermsOfUseModal isOpen={showTermsOfUse} onClose={() => setShowTermsOfUse(false)} />
        <ContactUsModal isOpen={showContactUs} onClose={() => setShowContactUs(false)} />
        <PageHeader gotoPage={scrollToPage} />
        <MainPage nextPage={nextPage} prevPage={prevPage} />
        <ListenPage />
        <WatchPage nextPage={nextPage} prevPage={prevPage} />
        <TourPage  />
        <StorePage />
        {/*removed signup page per client request*/}
        {/*<SignupPage />*/}
        <PageFooter
          handlePrivacyClick={handlePrivacyClick}
          handleTermsOfUseClick={handleTermsOfUseClick}
          handleContactUs={handleContactUs}
        />
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
