import { IconButton } from "@chakra-ui/react";
import { CY_GRAY_BG, externalNavigate } from "./utils";
import { BsInstagram, BsTiktok, BsSnapchat, BsFacebook, BsX, BsYoutube } from 'react-icons/bs';
import { SOCIALS } from "./utils";
import { FACEBOOK_LINK, INSTAGRAM_LINK, SNAPCHAT_LINK, TIKTOK_LINK, TWITTER_LINK, YOUTUBE_LINK } from "../data/links";

const SocialButton = ({ social, color, bgColor }) => {

    let text = "";
    let icon = "";
    let link = "";

    switch(social) {
      case SOCIALS.Twitter:
        text = "X";
        icon = <BsX />;
        link = TWITTER_LINK;
        break;
      case SOCIALS.Instagram:
        text = "Instagram";
        icon = <BsInstagram />;
        link = INSTAGRAM_LINK;
        break;
      case SOCIALS.TikTok:
        text = "TikTok";
        icon = <BsTiktok />;
        link = TIKTOK_LINK;
        break;
      case SOCIALS.Snapchat:
        text = "Snapchat";
        icon = <BsSnapchat />;
        link = SNAPCHAT_LINK;
        break;
      case SOCIALS.Facebook:
        text = "FaceBook";
        icon = <BsFacebook />;
        link = FACEBOOK_LINK;
        break; 
      case SOCIALS.YouTube:
        text = "YouTube";
        icon = <BsYoutube />;
        link = YOUTUBE_LINK;
        break;
      default:
        break;
    }
    return (
      <IconButton 
        bg={bgColor ?? CY_GRAY_BG} 
        aria-label={text} 
        icon={icon}
        size='sm'
        color={color ?? 'black'}
        onClick={() => externalNavigate(link, true)}
        style={{ marginInlineStart: '0rem' }}
      />
    );
  }

export default SocialButton;
