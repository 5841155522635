import { AspectRatio, Button, Flex, Hide, HStack, IconButton, Show } from "@chakra-ui/react";
import TopText from "../common/TopText";
import { TbCircleArrowLeft, TbCircleArrowRight } from 'react-icons/tb';
import { videos, WATCH_MORE_LINK } from "../data/videos";
import { useState } from 'react';
import { CY_BROWN, externalNavigate, SMALL_SCREEN_BREAKPOINT } from './../common/utils';

const WatchPage = () => {

  const [ index, setIndex ] = useState(0);
  const numberOfVideos = videos.length;

  const nextVideo = () => {
    console.log('app got next video event');
    const newIndex = index + 1;
    newIndex >= numberOfVideos ? setIndex(0) : setIndex(newIndex);
  }

  const prevVideo = () => {
    console.log('app got previous video event');
    const newIndex = index - 1;
    newIndex < 0 ? setIndex(numberOfVideos - 1) : setIndex(newIndex);
  }


  return (
    // <Flex>
    <Flex id='WATCH' bg='white' w='100%' justifyContent='center' flexDirection='column'>  
      <TopText text="WATCH" height='4em' width='20em' />
      <Flex >
        <Flex flexDirection='column' justifyContent='center'>
          <Hide breakpoint={SMALL_SCREEN_BREAKPOINT} >
            <IconButton aria-label='Left' bg=""
                    color="black" 
                    size='lg'
                    icon={<TbCircleArrowLeft/>}
                    onClick={prevVideo}/>
          </Hide>
        </Flex>
        <AspectRatio w='100%' ratio={ 16/9 } minWidth='200px'>
          <iframe
            title='Chris Young Video'
            src={videos[index].link}
            allowFullScreen
          />
        </AspectRatio>
        <Flex flexDirection='column' justifyContent='center'>
          <Hide breakpoint={SMALL_SCREEN_BREAKPOINT}>
            <IconButton aria-label='Right' bg=""
                    color="black" 
                    size='lg'
                    icon={<TbCircleArrowRight/>}
                    onClick={nextVideo}/>
          </Hide>
        </Flex>
      </Flex>
      <Show breakpoint={SMALL_SCREEN_BREAKPOINT}>
        <Flex justifyContent='center'>
            <IconButton aria-label='Left' bg=""
                    color="black" 
                    size='lg'
                    icon={<TbCircleArrowLeft/>}
                    onClick={prevVideo}/>
            <IconButton aria-label='Right' bg=""
                    color="black" 
                    size='lg'
                    icon={<TbCircleArrowRight/>}
                    onClick={nextVideo}/>
        </Flex>
      </Show>
      <HStack wrap='wrap' justifyContent='center' padding='2em'>   
        <Button bg={CY_BROWN}
              color='white'
              borderRadius='10em' 
              w='10em'
              onClick={() => externalNavigate(WATCH_MORE_LINK, true)}
              >
        watch more
      </Button>
    </HStack>
  </Flex>

  )
}

export default WatchPage;
