import { Card, CardBody, Text, Img, Stack } from "@chakra-ui/react";
import { CY_BROWN, CY_GRAY_BG } from "../common/utils";
import { externalNavigate } from "../common/utils";

const StoreItemCard = ({itemName, itemImage, price, shopLink }) => {

  return (
    <Card bg={CY_GRAY_BG} color={CY_BROWN}
          style={{ border: "none", boxShadow: "none" }}
      >
      <CardBody>
        <Img 
          w='12em'
          h='12em'
          src={itemImage}
          alt={itemName}
          style ={{ cursor: "pointer" }}
          onClick={() => externalNavigate(shopLink)}
        />
        <Stack maxWidth={'12em'}>
          <Text textAlign="center" fontWeight='bold'>{itemName}</Text>
          <Text textAlign="center">{price}</Text>
        </Stack>
      </CardBody>
    </Card>
  )
}

export default StoreItemCard;