import { IconButton } from "@chakra-ui/react";
import { externalNavigate } from "./utils";
import { FaAmazon, FaMusic, FaSpotify, FaYoutube  } from 'react-icons/fa';
import { MUSIC_SVCS, CY_BROWN } from "./utils";

const MusicSvcButton = ({ musicSvc, link, color, bgColor }) => {

    let text = "";
    let icon = "";

    switch(musicSvc) {
      case MUSIC_SVCS.Amazon:
        text = "Amazon";
        icon = <FaAmazon />;
        break;
      case MUSIC_SVCS.Apple:
        text = "Apple Music";
        icon = <FaMusic />;
        break;
      case MUSIC_SVCS.Spotify:
        text = "Spotify";
        icon = <FaSpotify />;
        break;
      case MUSIC_SVCS.YouTubeMusic:
        text = "YouTube";
        icon = <FaYoutube />;
        break;
      default:
        break;
    }
    return (
      <IconButton 
      aria-label={text} 
      icon={icon} 
      size='xs'
      bg={bgColor ?? ""} 
      color={color ?? CY_BROWN}
      onClick={() => externalNavigate(link, true)} 
      />
    );
  }

export default MusicSvcButton;
