import { SimpleGrid, Center, HStack } from "@chakra-ui/react";
import { CY_BROWN, CY_GRAY_BG } from "../common/utils";
import HeaderButton from "../common/HeaderButton";
import SocialStack from "../common/SocialStack";

const PageHeader = ({gotoPage}) => {

  return (
    <SimpleGrid 
      bg={CY_GRAY_BG} 
      flexDirection='wrap' 
      columns={3} 
      minChildWidth='15em'
      align-items='flex-end' >
        <Center h='3em'  fontSize='1.5rem' color={CY_BROWN} >
          CHRIS YOUNG 
        </Center>
        <HStack wrap='wrap' justifyContent='center'>
          <HeaderButton buttonText="Listen" onClick={gotoPage} navigateTag="LISTEN" color={CY_BROWN} />
          <HeaderButton buttonText="Watch" onClick={gotoPage} navigateTag="WATCH" color={CY_BROWN} />
          <HeaderButton buttonText="Tour" onClick={gotoPage} navigateTag="TOUR"  color={CY_BROWN}/>
          <HeaderButton buttonText="Store" onClick={gotoPage} navigateTag="STORE"  color={CY_BROWN}/>
        </HStack>
        <SocialStack color={CY_BROWN} />
    </SimpleGrid>
  )
}

export default PageHeader;
