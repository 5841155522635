import pinkTank from '../images/pink_tank.webp';
import whiteHat from '../images/white_hat.webp';
import blackHoodie from '../images/black_hoodie.webp';
import tee1 from '../images/YL&SN_tee.jpg';
import tee2 from '../images/CY_tour_photo_tee.jpg';
import tee3 from '../images/YL&SN_lyric_tee.jpg';
import ylsnVinylShirt from '../images/YL&SN_vinyl_shirt_combo.jpg'
import ylsnCdShirt from '../images/YL&SN_cd_shirt_combo.jpg'

import greyHoodie from '../images/LFY_Hoodie.jpg';

export const SHOP_MORE_LINK = 'https://store.chrisyoungcountry.com';

export const shopItems=[
  {
    name: 'YOUNG LOVE & SATURDAY NIGHT VINYL/SHIRT FAN PACK(PREORDER)',
    image: ylsnVinylShirt,
    price: '$50.00',
    link: 'https://store.chrisyoungcountry.com/products/young-love-saturday-night-vinyl-shirt-fan-packpreorder'
  },
  {
    name: 'YOUNG LOVE & SATURDAY NIGHTS CD/SHIRT FAN PACK(PREORDER)',
    image: ylsnCdShirt,
    price: '$35.00',
    link: 'https://store.chrisyoungcountry.com/products/young-love-saturday-nights-tee'
  },
  {
    name: 'YOUNG LOVE & SATURDAY NIGHTS LYRIC TEE',
    image: tee3,
    price: '$30.00',
    link: 'https://store.chrisyoungcountry.com/products/young-love-saturday-nights-tee'
  },
  // {
  //   name: 'Young Love & Saturday Nights Tee',
  //   image: tee1,
  //   price: '$30.00',
  //   link: 'https://store.chrisyoungcountry.com/products/young-love-saturday-nights-tee'
  // },
  // {
  //   name: '2023 Tour Photo Tee',
  //   image: tee2,
  //   price: '$30.00',
  //   link: 'https://store.chrisyoungcountry.com/products/2023-tour-photo-tee-1'
  // },
  // {
  //   name: 'Looking For You Hoodie',
  //   image: greyHoodie,
  //   price: '$60.00',
  //   link: 'https://store.chrisyoungcountry.com/products/looking-for-you-grey-hoodie'
  // },
  // {
  //   "name" : "Aw Naw Pink Tank",
  //   "image" : pinkTank,
  //   "price" : "$30.00",
  //   "link" : "https://store.chrisyoungcountry.com/products/aw-naw-pink-tank", 
  // },
  // {
  //   "name" : "White Camo Hat",
  //   "image" : whiteHat,
  //   "price" : "$40.00",
  //   "link" : "https://store.chrisyoungcountry.com/products/chris-young-white-camo-hat", 
  // },
  // {
  //   "name" : "Black Hoodie",
  //   "image" : blackHoodie,
  //   "price" : "$60.00",
  //   "link" : "https://store.chrisyoungcountry.com/products/cy-black-hoodie",
  // },
];  