
export const ALL_EVENTS_LINK = 'https://www.bandsintown.com/a/935-chris-young';
export const BANDSINTOWN_URL = 'https://rest.bandsintown.com/artists/Chris%20Young/events?app_id=5a4389e7735e99a86db1bc22c38beb43';

export const TWITTER_LINK = "https://twitter.com/chrisyoungmusic";
export const INSTAGRAM_LINK = "https://www.instagram.com/chrisyoungmusic/";
export const TIKTOK_LINK = "https://www.tiktok.com/@chrisyoungmusic";
export const SNAPCHAT_LINK = "https://www.snapchat.com/add/chrisyoungmusic";
export const FACEBOOK_LINK = "https://www.facebook.com/chrisyoungmusic/"; 
export const YOUTUBE_LINK = "https://www.youtube.com/channel/UCkfj9FmVIPO9KOe3DwPPdBA"

export const BOOKING_EMAIL_ADDR = 'NT@wmeagency.com';
export const MANAGEMENT_EMAIL_ADDR = 'info@wildcard-entertainment.com';