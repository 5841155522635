import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  Link
} from '@chakra-ui/react'

const PrivacyPolicyModal = ({ isOpen, onClose }) => {

    return <Modal onClose={onClose} size={'full'} isOpen={isOpen} scrollBehavior={'inside'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Privacy Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
                We at Chris Young Country know you care about how your personal information is used and
                shared, and we take your privacy seriously. Please read the following to learn more about how
                we collect, store, use and disclose information about you when you interact or use our websites
                (collectively the “Websites”) or any related events, trade shows, sales or marketing, and/or if you
                use any of our products, services or applications (collectively the “Services”) in any manner.
            </Text>
            <Text className="paragraph-spacing">
                WHAT DOES THIS PRIVACY POLICY COVER?
            </Text>
            <Text>
                This Privacy Policy covers our treatment of information that we gather when you are accessing
                or using our Websites or Services or when you contact us in any manner. We gather various
                types of information, including information that identifies you as an individual (“Personal
                Information”) from our users, as explained in more detail below.
            </Text>
            <Text className="paragraph-spacing">
                WHAT INFORMATION DOES CHRIS YOUNG COUNTRY COLLECT?
            </Text>
            <Text>
                INFORMATION YOU PROVIDE TO US:
            </Text>
            <Text>
                When you use our website We may collect any Personal Information that you choose to send to
                us or provide to us, for example, on our “Newsletter” (or similar) online form or if you register for
                a Chris Young Country Membership Account. If you contact us through the Websites, we will
                keep a record of our correspondence.
            </Text>
            <Text>
                When you use the Services: We receive and store information you provide directly to us. For
                example, when setting up new users, we collect Personal Information, such as name and e-mail
                address, to provide them with Services. The types of information we may collect directly from
                our customers include: name, username, email address, postal address, phone number,
                transactional information (including Services purchased), as well as any other contact or other
                information they choose to provide us or upload to our systems in connection with the Services.
            </Text>
            <Text className="paragraph-spacing">
                INFORMATION WE AUTOMATICALLY COLLECT:
            </Text>
            <Text>
                When you use the Websites: When you visit the Websites, we collect certain information related
                to your device, such as your device’s IP address, referring website, what pages your device
                visited, and the time that your device visited our Website.
            </Text>
            <Text className="paragraph-spacing">
                When you use the Services:
            </Text>
            <Text className="paragraph-spacing">
                Usage information: we keep track of user activity in relation to the types of Services our
                customers and their users use and performance metrics related to their use of the Services.
            </Text>
            <Text className="paragraph-spacing">
                Log information: we log information about our customers and their users when you use one of
                the Services including Internet Protocol (“IP”) address.
            </Text>
            <Text className="paragraph-spacing">
                Information collected by cookies and other similar technologies: we use various technologies to
                collect information which may include saving cookies to users’ computers.
            </Text>
            <Text className="paragraph-spacing">
                For further information, please see the section below headed "Cookie Policy".
            </Text>
            <Text className="paragraph-spacing">
                HOW DO WE USE THE INFORMATION?
            </Text>
            <Text>
                Websites: We will use the information we collect via our Websites:
            </Text>
            <Text className="paragraph-spacing">
                To administer our Website and for internal operations, including troubleshooting, data analysis,
                testing, statistical and survey purposes.
            </Text>
            <Text>
                To improve our Website to ensure that content is presented in the most effective manner for you
                and for your computer.
            </Text>
            <Text>
                To analyze customers’ use of the Websites for trend monitoring, marketing, and advertising
                purposes.
            </Text>
            <Text>
                For purposes made clear to you at the time you submit your information – for example, to
                provide you with information you have requested about our Services.
            </Text>
            <Text>
                As part of our efforts to keep our Website safe and secure.
            </Text>
            <Text>
                Services: We may use the information we collect from our customers and their users in
                connection with the Services we provide for a range of reasons, including to:
            </Text>
            <Text className="paragraph-spacing">
                Set up a user/membership account;
            </Text>
            <Text>
                Provide, operate and maintain the Services;
            </Text>
            <Text>
                Process and complete transactions, and send related information, including transaction
                confirmations and invoices;
            </Text>
            <Text>
                Manage use of the Services, respond to inquiries and comments and provide customer service
                and support;
            </Text>
            <Text>
                Send customers technical alerts, updates, security notifications, and administrative
                communications;
            </Text>
            <Text>
                Investigate and prevent fraudulent activities, unauthorized access to the Services, and other
                illegal activities; and
            </Text>
            <Text>
                For any other purposes about which we notify customers and users.
            </Text>
            <Text className="paragraph-spacing">
                We may also use the information you send to us via the Websites and/or Services, to
                communicate with you via email and, possibly, other means, regarding products, services,
                offers, promotions and events we think may be of interest to you or to send you our newsletter, if
                this is in accordance with your marketing preferences. However, you will always be able to
                opt-out of such communications at any time (see the “Your Choices” section below).
            </Text>
            <Text className="paragraph-spacing">
                HOW DO WE SHARE AND DISCLOSE INFORMATION TO THIRD PARTIES?
            </Text>
            <Text>
                We do not rent or sell your Personal Information to anyone. We may share and disclose
                information (including Personal Information) about our customers in the following limited
                circumstances:
            </Text>
            <Text>
                Vendors, consultants and other service providers: We may share your information with third
                party vendors, consultants and other service providers who we employ to perform tasks on our
                behalf. These companies include:
            </Text>
            <Text className="paragraph-spacing">
                Name Website
            </Text>
            <Text>
                Soltech, LLC <Link href={'https://soltech.net'} isExternal>https://soltech.net</Link>
            </Text>
            <Text>
                Personal Information in the United States and subsequently transfers that information to a third
                party agent or service provider for processing, Chris Young Country shall remain responsible for
                ensuring that such third party agent or service provider processes your Personal Information to
                the standard required by law (see the section below headed "International Data Transfers").
                Unless we tell you otherwise and you consent, our vendors do not have any right to use the
                Personal Information we share with them beyond what is necessary to assist us.
                Business Transfers: We may choose to buy or sell assets, and may share and/or transfer
                customer information in connection with the evaluation of and entry into such transactions. Also,
                if we (or our assets) are acquired, or if we go out of business, enter bankruptcy, or go through
                some other change of control, Personal Information could be one of the assets transferred to or
                acquired by a third party.
            </Text>
            <Text className="paragraph-spacing">
                Protection of Chris Young Country and Others: We reserve the right to access, read, preserve,
                and disclose any information as necessary to comply with law or court order; enforce or apply
                our agreements with you and other agreements; or protect the rights, property, or safety of Chris
                Young Country, our employees, our users, or others.
            </Text>
            <Text>
                Disclosures for National Security or Law Enforcement: Under certain circumstances, we may be
                required to disclose your Personal Information in response to valid requests by public
                authorities, including to meet national security or law enforcement requirements.
            </Text>
            <Text className="paragraph-spacing">
                YOUR CALIFORNIA PRIVACY RIGHTS
            </Text>
            <Text>
                This portion of our Privacy Notice advises California residents of the applicable rights as
                provided in the California Consumer Privacy Act (“CCPA”) and how to exercise these rights by
                communicating with us. We provide you with the rights described below when you use our
                Services. Please note that we will seek to verify your identity when we receive an individual
                rights request from you in order to ensure the security of your personal information.
            </Text>
            <Text className="paragraph-spacing">
                For purposes of this section, “Personal Information” is defined as information that identifies,
                relates to, describes, is reasonably capable of being associated with, or could reasonably be
                linked, directly or indirectly, with a particular consumer or household.
            </Text>
            <Text className="paragraph-spacing">
                If a California resident wishes to make any of the requests described below, such as access and
                deletion, he or she may contact us as provided in Section 13 (How to Contact Us) below.
            </Text>
            <Text className="paragraph-spacing">
                RIGHT TO DELETION OF PERSONAL INFORMATION - CALIFORNIA CONSUMERS
            </Text>
            <Text>
                California residents have the right to request the deletion of Personal Information as prescribed
                in Section 1798.105(a) of the CCPA. Chris Young Country may not delete some or all Personal
                Information if such Personal Information is necessary for us, or our service providers or
                affiliates, to:
            </Text>
            <Text>
                Complete the transaction for which the Personal Information was collected, provide a good or
                service requested by the consumer, or reasonably anticipated within the context of Chris Young
                Country’ ongoing business relationship with the consumer, or otherwise perform a contract
                between Chris Young Country and the consumer,
            </Text>
            <Text>
                Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or
                prosecute those responsible for that activity,
            </Text>
            <Text>
                Debug to identify and repair errors that impair existing intended functionality,
                Exercise free speech, ensure the right of another consumer to exercise his or her right of free
                speech, or exercise another right provided for by law,
            </Text>
            <Text>
                Comply with the California Electronic Communications Privacy Act pursuant to Chapter 3.6
                (commencing with Section 1546) of Title 12 of Part 2 of the Penal Code,
            </Text>
            <Text>
                Enable solely internal uses that are reasonably aligned with the expectations of the consumer
                based on the consumer’s relationship with Chris Young Country,
            </Text>
            <Text>
                Comply with a legal obligation, or
            </Text>
            <Text>
                Otherwise use the consumer’s Personal Information, internally, in a lawful manner that is
                compatible with the context in which the consumer provided the Personal Information.
            </Text>
            <Text>
                If you would like to access, review, update, rectify, and delete any Personal Information we hold
                about you, or exercise any other data subject right available to you under the EU General Data
                Protection Regulation (GDPR) and California Consumer Privacy Act (CCPA), you can revoke
                consent, or edit data here.
            </Text>
            <Text>
                RIGHT OF ACCESS: RIGHT TO REQUEST DISCLOSURE OF DATA COLLECTION AND
                SHARING PRACTICES - CALIFORNIA CONSUMERS
            </Text>
            <Text>
                You may request to receive details about how we collect, use, and share your Personal
                Information. Specifically, you may request to receive the specific pieces of information that we
                have collected about you by contacting us as provided in Section 13 (How to Contact Us) below.
            </Text>
            <Text className="paragraph-spacing">
                You may also request to receive:
            </Text>
            <Text className="paragraph-spacing">
                the categories of Personal Information that we have collected about you,
            </Text>
            <Text>
                the categories of Personal Information that we have disclosed for a business purpose,
            </Text>
            <Text>
                the categories of sources from which we collected the Personal Information,
            </Text>
            <Text>
                our purposes for collecting that Personal Information, and
            </Text>
            <Text>
                the categories of parties with whom we share your Personal Information.
            </Text>
            <Text>
                RIGHT TO NOT BE DISCRIMINATED AGAINST FOR EXERCISING YOUR RIGHTS
                -CALIFORNIA CONSUMERS
            </Text>
            <Text>
                We do not discriminate against you for exercising any of your CCPA rights, such as your access
                and deletion rights described above. However, we may offer you rewards (such as through our
                loyalty program) or benefits (such as customizing your experience at a resort) which we need
                your data to provide, but we will only provide those rewards or benefits if their value to you is
                reasonably related to the value provided to Chris Young Country by your Personal Information.
            </Text>
            <Text className="paragraph-spacing">
                CATEGORIES OF PERSONAL INFORMATION COLLECTED ABOUT CALIFORNIA
                CONSUMERS AND THE PURPOSES OF SUCH COLLECTION
            </Text>
            <Text>
                We collect identifiers that you may choose to provide to us, such as your name, email address,
                mailing address, phone number, birthday, and age. We use this information to communicate with
                you, market goods and services, and to provide you with goods and services.
            </Text>
            <Text className="paragraph-spacing">
                We collect and use identifiers (such as a username) you may choose to provide to us, and the
                content of your communications when you post information on our online message boards or
                online public forums. We use this information to run our online message boards or online public
                forums.
            </Text>
            <Text className="paragraph-spacing">
                CATEGORIES OF PERSONAL INFORMATION SOLD ABOUT CALIFORNIA CONSUMERS
            </Text>
            <Text>
                Chris Young Country does not sell consumers’ Personal Information.
            </Text>
            <Text>
                CATEGORIES OF PERSONAL INFORMATION DISCLOSED FOR A BUSINESS PURPOSE -
                CALIFORNIA CONSUMERS
            </Text>
            <Text>
                Chris Young Country discloses identifiers (e.g., name or email address), internet activity,
                consumer history, and consumers’ preferences to provide marketing and analytics services to its
                business partners.
            </Text>
            <Text className="paragraph-spacing">
                Chris Young Country discloses identifiers, consumer history, and consumers’ preferences to our
                franchisees and licensees to offer our Services.
            </Text>
            <Text className="paragraph-spacing">
                Chris Young Country may share your personal information with third party service providers that
                perform a variety of services on our behalf, such as the development and analysis of our social
                media pages, operating and maintaining our websites, offering or managing promotions, and
                market research purposes.
            </Text>
            <Text className="paragraph-spacing">
                Chris Young Country may share your personal information with other entities for purposes of a
                sale, merger, or other related corporate event. An entity that buys us or a part of our business
                may continue to use your personal information in accordance with this Privacy Notice.
            </Text>
            <Text>
                REMOVING CONTENT ON PUBLIC FORUMS - CALIFORNIA CONSUMERS
            </Text>
            <Text>
                If you are a California resident between the age of 13 and 18 and are a registered user on the
                Service and participate in the online public forums offered through the Service, you may request
                that we remove content or information about you that you posted by (a) submitting a request in
                writing to with the subject: “Deletion Request”, and (b) clearly identifying the content or
                information you wish to have removed and providing sufficient information to allow us to locate
                the content or information to be removed. However, please note that we are not required to
                erase or otherwise eliminate content or information if (i) other state or federal laws require us or
                a third party to maintain the content or information; (ii) the content or information was posted,
                stored, or republished by another user; (iii) the content or information is anonymized so that you
                cannot be individually identified; (iv) you do not follow the instructions posted in this Privacy
                Notice on how to request removal of your content or information; or (v) you have received
                compensation or other consideration for providing the content. Further, nothing in this provision
                shall be construed to limit the authority of a law enforcement agency to obtain the applicable
                content or information.
            </Text>
            <Text>
                IS PERSONAL INFORMATION ABOUT ME SECURE?
            </Text>
            <Text>
                We use technical, organizational and administrative security measures to protect all information
                we hold in our records from loss, misuse, and unauthorized access, disclosure, alteration and
                destruction. Unfortunately, no company or service can guarantee complete security.
                Unauthorized entry or use, hardware or software failure, and other factors, may compromise the
                security of user information. Among other practices, your account is protected by a password for
                your privacy and security. You must prevent unauthorized access to your account and Personal
                Information by selecting and protecting your password appropriately and limiting access to your
                computer or device and browser by signing off after you have finished accessing your account.
            </Text>
            <Text className="paragraph-spacing">
                COOKIE POLICY
            </Text>
            <Text>
                A cookie is a small piece of data (text file) that a website – when visited by a user – asks your
                browser to store on your device in order to remember information about you, such as your
                language preference or login information. Those cookies are set by us and called first party
                cookies. We also use third party cookies – which are cookies from a domain different than the
                domain of the website you are visiting – for our advertising and marketing efforts.
            </Text>
            <Text className="paragraph-spacing">
                More specifically, we use cookies and other tracking technologies for the following purposes:
            </Text>
            <Text className="paragraph-spacing">
                Assisting you in navigation.
            </Text>
            <Text>
                Assisting in login and your ability to provide feedback;
            </Text>
            <Text>
                Analyzing your use of our products, services or applications;
            </Text>
            <Text>
                Assisting with our promotional and marketing efforts.
            </Text>
            <Text>
                Below is a detailed list of the cookies we use on our Website. Our Website is scanned with our
                cookie scanning tool regularly to maintain a list as accurate as possible. We classify cookies in
                the following categories:
            </Text>
            <Text className="paragraph-spacing">
                Strictly Necessary Cookies
            </Text>
            <Text>
                Statistics Cookies
            </Text>
            <Text>
                You can change cookie settings here
            </Text>
            <Text>
                STRICTLY NECESSARY COOKIES
            </Text>
            <Text>
                These cookies are necessary for the website to function and cannot be switched off in our
                systems. They are usually only set in response to actions made by you which amount to a
                request for services, such as setting your privacy preferences, logging in or filling in forms.
            </Text>
            <Text className="paragraph-spacing">
                You can set your browser to block or alert you about these cookies, but some parts of the site
                will not work. These cookies do not store any personally identifiable information.
            </Text>
            <Text className="paragraph-spacing">
                Chris Young Country - <Link isExternal href={`https://www.chrisyoungcountry.com`}>www.chrisyoungcountry.com</Link>
            </Text>
            <Text>
                Soltech, LLC - <Link href={'https://soltech.net'} isExternal>https://soltech.net</Link>
            </Text>
            <Text>
                STATISTICS COOKIES
            </Text>
            <Text>
                These cookies allow us to count visits and traffic sources so we can measure and improve the
                performance of our site. They help us know which pages are the most and least popular and
                see how visitors move around the site. They may be set by us or by third party providers whose
                services we have added to our pages. They may be used by those companies to build a profile
                of your interests and show you relevant adverts on other sites.
            </Text>
            <Text className="paragraph-spacing">
                They do not store personal information directly but are based on uniquely identifying your
                browser and internet device. If you do not allow these cookies, you will experience less targeted
                advertising.
            </Text>
            <Text className="paragraph-spacing">
                AdRoll Group - <Link href={'https://www.adroll.com'} isExternal>www.adroll.com</Link>
            </Text>
            <Text>
                AEG Presents - <Link href={'https://www.aegpresents.com'} isExternal>www.aegpresents.com</Link>
            </Text>
            <Text>
                AppNexus - <Link href={'https://www.appnexus.com'} isExternal>www.appnexus.com</Link>
            </Text>
            <Text>
                BidSwitch - <Link href={'https://www.bidswitch.com'} isExternal>www.bidswitch.com</Link>
            </Text>
            <Text>
                Facebook API - <Link href={'https://www.facebook.com'} isExternal>www.facebook.com</Link>
            </Text>
            <Text>
                Google Analytics - <Link href={'https://google.com'} isExternal>google.com</Link>
            </Text>
            <Text>
                LiveRamp Inc. - <Link href={'https://liveramp.com'} isExternal>liveramp.com</Link>
            </Text>
            <Text>
                MediaMath Inc. - <Link href={'https://www.mediamath.com'} isExternal>www.mediamath.com</Link>
            </Text>
            <Text>
                OpenX - <Link href={'https://www.openx.com'} isExternal>www.openx.com</Link>
            </Text>
            <Text>
                DataLogix Oracle - <Link href={'https://www.oracle.com'} isExternal>www.oracle.com</Link>
            </Text>
            <Text>
                ShareThis - <Link href={'https://www.sharethis.com'} isExternal>www.sharethis.com</Link>
            </Text>
            <Text>
                YOUR PRIVACY RIGHTS
            </Text>
            <Text>
                WHAT CHOICES DO I HAVE?
            </Text>
            <Text>
                You can always choose not to disclose information to us, but keep in mind some information
                may be needed to register with us or to take advantage of some of our features.
            </Text>
            <Text className="paragraph-spacing">
                COOKIES
            </Text>
            <Text>
                You can accept or reject cookies through our Privacy Preference Centre, accessible by clicking
                the “cookie settings” button in our cookie policy. You can also do so by adjusting your web
                browser controls. Please consult our Cookie Policy for more information about our use of
                cookies and how to accept and reject them.
            </Text>
            <Text className="paragraph-spacing">
                MARKETING COMMUNICATIONS
            </Text>
            <Text>
                You can opt-out of receiving certain promotional or marketing communications from us at any
                time by using the unsubscribe link in the emails communications we send. Or revoke consent or
                edit data here.
            </Text>
            <Text>
                If you have any account for our Services, we will still send you non-promotional
                communications, like service-related emails.
            </Text>
            <Text className="paragraph-spacing">
                HOW CAN I UPDATE AND ACCESS MY INFORMATION (EXERCISE MY DATA SUBJECT
                RIGHTS)?
            </Text>
            <Text>
                If you would like to access, review, update, rectify, and delete any Personal Information we hold
                about you, or exercise any other data subject right available to you under the EU General Data
                Protection Regulation (GDPR) and California Consumer Privacy Act (CCPA), you can revoke
                consent, or edit data here.
            </Text>
            <Text>
                Our Privacy team will examine your request and respond to you as quickly as possible!
            </Text>
            <Text className="paragraph-spacing">
                Please note that we may still use any aggregated and de-identified Personal Information that
                does not identify any individual and may also retain and use your information as necessary to
                comply with our legal obligations, resolve disputes, and enforce our agreements.
            </Text>
            <Text className="paragraph-spacing">
                California residents are entitled to ask us for a notice identifying the categories of Personal
                Information that we share with our affiliates and/or third parties for marketing purposes and
                providing contact information for such affiliates and/or third parties. If you are a California
                resident and would like a copy of this notice, please submit a written request to:
            </Text>
            <Text>
                INTERNATIONAL DATA TRANSFERS
            </Text>
            <Text>
                Personal Information you submit on the Websites or through the Services is sent to the United
                States and will be primarily processed by us in the United States and potentially in other
                countries, on our servers or on our hosted service providers cloud servers on our behalf. These
                countries may not have similar data protection laws to those in your country of residence.
                However, we will always protect your information in accordance with this Privacy Policy
                wherever it is processed. To request this capability, contact
            </Text>
            <Text>
                LINKED WEBSITES
            </Text>
            <Text>
                For your convenience, hyperlinks may be posted on the Websites that link to other websites (the
                “Linked Sites”). We are not responsible for, and this Privacy Policy does not apply to, the privacy
                practices of any Linked Sites or of any companies that we do not own or control. Linked Sites
                may collect information in addition to that which we collect on the Websites. We do not endorse
                any of these Linked Sites, the services or products described or offered on such Linked Sites, or
                any of the content contained on the Linked Sites. We encourage you to seek out and read the
                privacy policy of each Linked Site that you visit to understand how the information that is
                collected about you is used and protected.
            </Text>
            <Text className="paragraph-spacing">
                CHILDREN
            </Text>
            <Text>
                Our Services are not directed to children under the age of 13. We adhere to the Children’s
                Online Privacy Protection Act (“COPPA”) and will not knowingly collect personal information
                from any child under the age of 13 unless a parent or guardian consents to our collection and
                use of this information. If a parent or guardian becomes aware that his or her child has provided
                us with personal information without his or her consent, he or she should contact us If we
                become aware that a child under 13 has provided us with personal information, we will take
                steps to delete the information from our files.
            </Text>
            <Text className="paragraph-spacing">
                WILL CHRIS YOUNG COUNTRY EVER CHANGE THIS PRIVACY POLICY?
            </Text>
            <Text>
                We’re constantly improving our Websites and Services, so we may need to change this Privacy
                Policy from time to time as well. We will alert you to material changes by, for example, placing a
                notice on our Websites and/or by sending you an email (if you have registered your e-mail
                details with us) when we are required to do so by applicable law. You can see when this Privacy
                Policy was last updated by checking the date at the top of this page. You are responsible for
                periodically reviewing this Privacy Policy.
            </Text>
            <Text className="paragraph-spacing">
                WHAT IF I HAVE QUESTIONS ABOUT THIS POLICY?
            </Text>
            <Text>
                If you have any questions or concerns regarding our privacy policies, please send us a detailed
                message to and we will try to resolve your concerns.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
}

export default PrivacyPolicyModal;