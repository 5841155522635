import { Button, Stack, HStack, Flex } from "@chakra-ui/react";
import TopText from '../common/TopText';
import ListenCard from "./LIstenCard";
import { CY_GRAY_BG } from "../common/utils";
import { songs, HEAR_MORE_LINK } from "../data/songs";
import { CY_BROWN, externalNavigate } from './../common/utils';

const ListenPage = () => {

  return (  
    <Flex id='LISTEN' bg={CY_GRAY_BG} w='100%' justifyContent='center'>  
      <Stack>
        <TopText text="LISTEN" height='4em' width='30em' />
        <Flex wrap='wrap' w='100%' justifyContent='space-around' 
              columnGap='6em' >
          {songs && songs.map((song, index) => {
            return <ListenCard 
                    key={index} 
                    songImage={song.image} 
                    songName={song.name}
                    amazonLink={song.amazon}
                    appleLink={song.apple}
                    spotifyLink={song.spotify}
                    youTubeLink={song.youtube}
                    offset={index == 1}
                  />  
                }
          )}
        </Flex>
        <HStack wrap='wrap' h='6em' justifyContent='center'>   
          <Button borderRadius='10em' 
                  bg='#FFFFFF'
                  w='10em'
                  color={CY_BROWN}
                  onClick={() => externalNavigate(HEAR_MORE_LINK, true)}
                  >
            hear more
          </Button>
        </HStack>
      </Stack>
  </Flex>
  )
}

export default ListenPage;
