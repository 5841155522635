import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  Link
} from '@chakra-ui/react'

const TermsOfUseModal = ({ isOpen, onClose }) => {

    return <Modal onClose={onClose} size={'full'} isOpen={isOpen} scrollBehavior={'inside'}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>TERMS OF USE</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Text>
                    TERMS OF WEBSITE USE
                </Text>
                <Text>
                    For the purposes of this document, "company" is defined as Chris Young Country. The following
                    are terms of a legal agreement (“Agreement”) between you and Company. These terms and
                    conditions apply to a user (“user,” “you,” or “your”) who accesses, browses and/or otherwise
                    uses this Web site (“Site”) and/or the services provided by this Site (“Services”). By accessing,
                    browsing and/or otherwise using this Site, you acknowledge that you have read, understood and
                    agreed to be bound by these terms and conditions, and to comply with all applicable laws and
                    regulations, including U.S. export and re-export control laws and regulations. If you do not agree
                    to all of these terms and conditions, you may not access, browse and/or use this Site. The
                    material provided on this Site is protected by law, including, but not limited to, United States
                    copyright law and international treaties. Please read this Agreement carefully as it governs your
                    use of the Services and this Site. If you have any questions regarding this Agreement, or any
                    agreement or document herein incorporated, please contact Company at (This email address is
                    only for usage questions, please do not send customer service questions via this address.).
                    Please read these terms of use carefully before you start to use the site. By using our site, you
                    indicate that you accept these terms of use and that you agree to abide by them. If you do not
                    agree to these terms of use, please refrain from using our site.
                </Text>
                <Text className="paragraph-spacing">
                    RELIANCE ON INFORMATION POSTED & DISCLAIMER
                </Text>
                <Text>
                    The materials contained on our site are provided for general information purposes only and do
                    not claim to be or constitute legal or other professional advice and shall not be relied upon as
                    such.
                </Text>
                <Text className="paragraph-spacing">
                    We do not accept any responsibility for any loss which may arise from accessing or reliance on
                    the information on this site and to the fullest extent permitted by law, we exclude all liability for
                    loss or damages direct or indirect arising from use of this site.
                </Text>
                <Text className="paragraph-spacing">
                    ACCESSING OUR SITE
                </Text>
                <Text>
                    Access to our site is permitted on a temporary basis, and we reserve the right to withdraw or
                    amend the service we provide on our site without notice (see below). We will not be liable if for
                    any reason our site is unavailable at any time or for any period.
                </Text>
                <Text className="paragraph-spacing">
                    INTELLECTUAL PROPERTY RIGHTS
                </Text>
                <Text>
                    We are the owner or the licensee of all intellectual property rights in our site, and in the material
                    published on it. Those works are protected by copyright laws and treaties around the world. All
                    such rights are reserved.
                </Text>
                <Text className="paragraph-spacing">
                    You may print off one copy, and may download extracts, of any page(s) from our site for your
                    personal reference and you may draw the attention of others within your organisation to material
                    posted on our site.
                </Text>
                <Text className="paragraph-spacing">
                    You must not modify the paper or digital copies of any materials you have printed off or
                    downloaded in any way, and you must not use any illustrations, photographs, video or audio
                    sequences or any graphics separately from any accompanying text.
                </Text>
                <Text className="paragraph-spacing">
                    Our status (and that of any identified contributors) as the authors of material on our site must
                    always be acknowledged.
                </Text>
                <Text className="paragraph-spacing">
                    You must not use any part of the materials on our site for commercial purposes without
                    obtaining a license to do so from us or our licensors.
                </Text>
                <Text className="paragraph-spacing">
                    If you print off, copy or download any part of our site in breach of these terms of use, your right
                    to use our site will cease immediately and you must, at our option, return or destroy any copies
                    of the materials you have made.
                </Text>
                <Text className="paragraph-spacing">
                    OUR SITE CHANGES REGULARLY
                </Text>
                <Text>
                    We aim to update our site regularly, and may change the content at any time. If the need arises,
                    we may suspend access to our site, or close it indefinitely. Any of the material on our site may
                    be out of date at any given time, and we are under no obligation to update such material.
                </Text>
                <Text className="paragraph-spacing">
                    OUR LIABILITY
                </Text>
                <Text>
                    The material displayed on our site is provided without any guarantees, conditions or warranties
                    as to its accuracy. To the extent permitted by law, we, and third parties connected to us hereby
                    expressly exclude:
                </Text>
                <Text className="paragraph-spacing">
                    All conditions, warranties and other terms which might otherwise be implied by statute, common
                    law or the law of equity.
                </Text>
                <Text>
                    Any liability for any direct, indirect or consequential loss or damage incurred by any user in
                    connection with our site or in connection with the use, inability to use, or results of the use of our
                    site, any websites linked to it and any materials posted on it, including, without limitation any
                    liability for:
                </Text>
                <Text>
                    loss of income or revenue;
                </Text>
                <Text>
                    loss of business;
                </Text>
                <Text>
                    loss of profits or contracts;
                </Text>
                <Text>
                    loss of anticipated savings;
                </Text>
                <Text>
                    loss of data;
                </Text>
                <Text>
                    loss of goodwill;
                </Text>
                <Text>
                    wasted management or office time; and
                </Text>
                <Text>
                    for any other loss or damage of any kind, however arising and whether caused by tort (including
                    negligence), breach of contract or otherwise, even if foreseeable, provided that this condition
                    shall not prevent claims for loss of or damage to your tangible property or any other claims for
                    direct financial loss that are not excluded by any of the categories set out above.
                </Text>
                <Text className="paragraph-spacing">
                    This does not affect our liability for death or personal injury arising from our negligence, nor our
                    liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, nor
                    any other liability which cannot be excluded or limited under applicable law.
                </Text>
                <Text className="paragraph-spacing">
                    INFORMATION ABOUT YOU AND YOUR VISITS TO OUR SITE
                </Text>
                <Text>
                    We process information about you in accordance with our privacy policy. By using our site, you
                    consent to such processing and you warrant that all data provided by you is accurate.
                </Text>
                <Text className="paragraph-spacing">
                    VIRUSES, HACKING AND OTHER OFFENCES
                </Text>
                <Text>
                    You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or
                    other material which is malicious or technologically harmful. You must not attempt to gain
                    unauthorised access to our site, the server on which our site is stored or any server, computer
                    or database connected to our site. You must not attack our site via a denial-of-service attack or
                    a distributed denial-of service attack.
                </Text>
                <Text className="paragraph-spacing">
                    By breaching this provision, you would commit a criminal offense under the Computer Fraud
                    and Abuse Act 1986. We will report any such breach to the relevant law enforcement authorities
                    and we will co-operate with those authorities by disclosing your identity to them. In the event of
                    such a breach, your right to use our site will cease immediately.
                </Text>
                <Text className="paragraph-spacing">
                    We will not be liable for any loss or damage caused by a distributed denial-of-service attack,
                    viruses or other technologically harmful material that may infect your computer equipment,
                    computer programs, data or other proprietary material due to your use of our site or to your
                    downloading of any material posted on it, or on any website linked to it.
                </Text>
                <Text className="paragraph-spacing">
                    LINKS FROM OUR SITE
                </Text>
                <Text>
                    Where our site contains links to other sites and resources provided by third parties, these links
                    are provided for your information only. We have no control over the contents of those sites or
                    resources, and accept no responsibility for them or for any loss or damage that may arise from
                    your use of them. When accessing a site via our website we advise you check their terms of
                    use and privacy policies to ensure compliance and determine how they may use your
                    information.
                </Text>
                <Text className="paragraph-spacing">
                    OTHER COUNTRIES
                </Text>
                <Text>
                    We control and operate the Site from our offices in the United States of America. We do not
                    represent that materials on the Site are appropriate or available for use in other locations.
                    Persons who choose to access the Site from other locations do so at their own risk, and are
                    responsible for compliance with the laws of that territory.
                </Text>
                <Text className="paragraph-spacing">
                    JURISDICTION AND APPLICABLE LAW
                </Text>
                <Text>
                    The Texas courts will have non-exclusive jurisdiction over any claim arising from, or related to, a
                    visit to our site.
                </Text>
                <Text className="paragraph-spacing">
                    These terms of use and any dispute or claim arising out of or in connection with them or their
                    subject matter or formation (including non-contractual disputes or claims) shall be governed by
                    and construed in accordance with the law of Texas
                </Text>
                <Text className="paragraph-spacing">
                    NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT OR INTELLECTUAL PROPERTY INFRINGEMENT
                </Text>
                <Text>
                    Company respects the intellectual property of others, and we ask our Users to do the same.
                    Company may, in appropriate circumstances and at its discretion, disable and/or terminate the
                    accounts of users who may be repeat infringers. If you believe that your work has been copied
                    in a way that constitutes copyright infringement, or your intellectual property rights have been
                    otherwise violated, please provide Company with the following information:
                </Text>
                <Text className="paragraph-spacing">
                    an electronic or physical signature of the person authorized to act on behalf of the owner of the
                    copyright or other intellectual property interest;
                </Text>
                <Text>
                    a description of the copyrighted work or other intellectual property that you claim has been
                    infringed;
                </Text>
                <Text>
                    a description of where the material that you claim is infringing is located on the site;
                    your address, telephone number, and email address;
                </Text>
                <Text>
                    a statement by you that you have a good faith belief that the disputed use is not authorized by
                    the copyright owner, its agent, or the law;
                </Text>
                <Text>
                    a statement by you, made under penalty of perjury, that the above information in your Notice is
                    accurate and that you are the copyright or intellectual property owner or authorized to act on the
                    copyright or intellectual property owner's behalf.
                </Text>
                <Text>
                    Company may be reached for notice of claims of copyright or other intellectual property
                    infringement can be reached as follows: By email:
                </Text>
                <Text>
                    STATUTE OF LIMITATIONS
                </Text>
                <Text>
                    You agree that regardless of any statute or law to the contrary, any claim or cause of action
                    arising out of or related to use of this Site, the Services, or this Agreement (or any other
                    agreement incorporated herein), must be filed within one (1) year after such claim or cause of
                    action arose or forever be barred.
                </Text>
                <Text className="paragraph-spacing">
                    VARIATIONS
                </Text>
                <Text>
                    We may revise these terms of use at any time by amending this page. You are expected to
                    check this page from time to time to take notice of any changes we made, as they are binding
                    on you. Some of the provisions contained in these terms of use may also be superseded by
                    provisions or notices published elsewhere on our site.
                </Text>
                <Text className="paragraph-spacing">
                    LIMITATION OF LIABILITY
                </Text>
                <Text>
                    IN NO EVENT SHALL COMPANY OR ANY COMPANY THIRD PARTIES BE LIABLE FOR ANY
                    DAMAGES, INCLUDING, WITHOUT LIMITATION DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                    OR CONSEQUENTIAL DAMAGES, DAMAGES RESULTING FROM LOST PROFITS, LOST
                    OR DAMAGED DATA OR BUSINESS INTERRUPTION ARISING OUT OF OR RELATING TO
                    THE USE, OR INABILITY TO USE, THE SERVICES, THIS SITE, ANY WEBSITES LINKED TO
                    THIS SITE, THE MATERIALS, SOFTWARE OR OTHER INFORMATION CONTAINED IN ANY
                    OR ALL SUCH SITES, WHETHER BASED ON WARRANTY, CONTRACTS, STATUTES,
                    REGULATIONS, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE) OR ANY
                    OTHER LEGAL THEORY AND WHETHER OR NOT ADVISED OF THE POSSIBILITY OF
                    SUCH DAMAGES. IF YOUR USE OF THE MATERIALS OR INFORMATION FROM THIS SITE
                    RESULTS IN THE NEED FOR SERVICING, REPAIR OR CORRECTION OF EQUIPMENT OR
                    DATA, YOU ASSUME ALL COSTS THEREOF. SOME STATES DO NOT ALLOW THE
                    EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
                    ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL THE
                    TOTAL AND AGGREGATE LIABILITY OF ANY PARTY UNDER THIS AGREEMENT FOR ANY
                    CAUSE OF ACTION OR REASON WHATSOEVER EXCEED $100.00 OR THE TOTAL FEES
                    YOU PAID TO US IN DURING THE 3 MONTH PERIOD PRECEDING THE EVENT GIVING
                    RISE TO THE LIABILITY, WHICHEVER IS GREATER. YOUR SOLE AND EXCLUSIVE
                    REMEDY UNDER THIS AGREEMENT IS TO DISCONTINUE THE USE OF THE SERVICES.
                    THE LIABILITY OF ANY PARTY UNDER THIS AGREEMENT SHALL BE CUMULATIVE AND
                    NOT PER INCIDENT
                </Text>
                <Text className="paragraph-spacing">
                    DISCLAIMER OF WARRANTIES
                </Text>
                <Text>
                    YOU AGREE THAT USE OF THE SERVICES AND THIS SITE IS AT YOUR SOLE RISK. THE
                    SERVICES AND THIS SITE, INCLUDING BUT NOT LIMITED TO ALL SOFTWARE,
                    FUNCTIONS, MATERIALS, AND INFORMATION, ARE PROVIDED ON AN “AS IS” AND “AS
                    AVAILABLE” BASIS, AND COMPANY (INCLUDING, WITHOUT LIMITATION, ITS
                    SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS,
                    LICENSORS, INDEPENDENT CONSULTANTS, SUBCONTRACTORS, DISTRIBUTORS, OR
                    ANY CLIENT OF COMPANY (COLLECTIVELY, “COMPANY THIRD PARTIES”)) ASSUMES NO
                    RESPONSIBILITY FOR THE TIMELINESS, DELETION, MIS-DELIVERY OR FAILURE TO
                    STORE ANY OF YOUR COMMUNICATIONS, DATA, CONTENT, OR PERSONALIZATION
                    SETTINGS. TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW, COMPANY AND
                    COMPANY THIRD PARTIES HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND,
                    WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                    NON-INFRINGEMENT, QUIET ENJOYMENT, TITLE, MERCHANTABILITY OF COMPUTER
                    PROGRAMS AND INFORMATIONAL CONTENT. NEITHER COMPANY NOR ANY COMPANY
                    THIRD PARTIES MAKE ANY WARRANTY THAT THIS SITE, THE SOFTWARE, THE
                    MATERIALS, THE PRODUCTS, OR THE SERVICES WILL BE UNINTERRUPTED, TIMELY,
                    SECURE, OR ERROR OR VIRUS FREE OR THAT ANY DEFECTS IN THE SITE, THE
                    SOFTWARE, THE MATERIALS, THE PRODUCTS, OR THE SERVICES WILL BE
                    CORRECTED; NOR DO COMPANY OR ANY COMPANY THIRD PARTIES MAKE ANY
                    WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM, OR THE ACCURACY
                    OR RELIABILITY OF ANY INFORMATION OBTAINED THROUGH, THE USE OF THE
                    SERVICES OR THIS SITE. YOU UNDERSTAND AND AGREE THAT ANY MATERIAL AND/OR
                    DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE AND
                    THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE
                    SOLELY RESPONSIBLE FOR ANY DAMAGE TO A COMPUTER SYSTEM OR LOSS OF
                    DATA THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL AND/OR DATA. NO
                    ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
                    THROUGH THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE
                    HEREIN. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
                    OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
                    YOU, SOME OR ALL OF THE DISCLAIMERS CONTAINED HEREIN MAY NOT APPLY TO
                    YOU AND YOU MAY HAVE ADDITIONAL RIGHTS.
                </Text>
                <Text className="paragraph-spacing">
                    YOUR CONCERNS
                </Text>
                <Text>
                    If you have any concerns about material which appears on our site, please contact
                </Text>
                <Text className="paragraph-spacing">
                    Thank you for visiting our site.
                </Text>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
}

export default TermsOfUseModal;