import { Card, CardBody, CardHeader, Hide, HStack, Stack, Text, Img } from "@chakra-ui/react";
import { CY_GRAY_BG, MUSIC_SVCS, SMALL_SCREEN_BREAKPOINT } from "../common/utils";
import MusicSvcButton from "../common/MusicSvcButton";

const ListenCard = ({songName, songImage, amazonLink, 
                    appleLink, spotifyLink, youTubeLink, offset }) => {

  function addOffset() {
    if (offset) {
      return <CardHeader></CardHeader>
    }
    else {
      return <></>
    }
  }
  return (
    <Card
          bg={CY_GRAY_BG} 
          style={{ border: "none", boxShadow: "none" }}
          justifyContent='space-evenly'>
        <Hide breakpoint={SMALL_SCREEN_BREAKPOINT} >
          {addOffset()}
        </Hide>
      <CardBody w='12em'>
        <Img 
          src={songImage}
          alt={songName}
        />
        <Stack>
          <Text fontSize='.75em' fontWeight='bold'>{songName}</Text>
          <HStack>
            <MusicSvcButton musicSvc={MUSIC_SVCS.Amazon} link={amazonLink} />
            <MusicSvcButton musicSvc={MUSIC_SVCS.Apple} link={appleLink} />
            <MusicSvcButton musicSvc={MUSIC_SVCS.Spotify} link={spotifyLink} />
            <MusicSvcButton musicSvc={MUSIC_SVCS.YouTubeMusic} link={youTubeLink} />
          </HStack>
        </Stack>
        </CardBody>
    </Card>
  )
}

export default ListenCard;