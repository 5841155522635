export const CY_BROWN = '#442221';
export const CY_GRAY_BG = '#EBEBEB';
export const CY_GRAY_BORDER = '#CCCCCC';
export const CY_GRAY_TEXT = '#707070';

export const SMALL_SCREEN_BREAKPOINT = '(max-width: 600px)';

export const PAGES = [
  'MAIN',
  'LISTEN',
  'WATCH',
  'TOUR',
  'STORE',
  'SIGNUP' 
];

export const SOCIALS = {
  Twitter: Symbol("Twitter"),
  Instagram: Symbol("Instagram"),
  TikTok: Symbol("TikTok"),
  Snapchat: Symbol("Snapchat"),
  Facebook: Symbol("Facebook"),
  YouTube: Symbol("YouTube")
};

export const MUSIC_SVCS = {
  Amazon: Symbol("Amazon"),
  Apple: Symbol("Apple"),
  Spotify: Symbol("Spotify"),
  YouTubeMusic: Symbol("YouTubeMusic"),
};

export const externalNavigate = (url, newWindow) => {
  // alert('navigating to: ' + url);
  console.log('navigating to: ', url);
  if (newWindow) {
    window.open(url, '_blank')
  }
  else {
    window.location.href = url;
  }
  return null;
}