import lookingForYouImg from '../images/looking_for_you.png';
import youngLoveImg from '../images/yl&sn.jpg';
import rightNowImg from '../images/Right_Now_Cover_art.jpg';
import famousFriendsImg from '../images/famous_friends.png';
import imCominOverImg from '../images/im_comin_over.png'
import doubleDownImg from '../images/CY_DD_COVER.jpg';
import whatSheSeesInMeImg from '../images/CY_WSSIM.jpg';

export const featuredSong = {
  "name" : "Young Love & Saturday Nights",
  "artist" : "Chris Young",
  "image" : youngLoveImg,
  "url" : "YL&SN.mp3"
}

export const HEAR_MORE_LINK = 'https://open.spotify.com/artist/4BYxqVkZyFjtik7crYLg5Q';

export const songs = [
  {
    "name" : "Young Love & Saturday Nights",
    "image" : youngLoveImg,
    "pivot": "",
    "amazon" : "http://music.amazon.com/albums/B0CQ423V7T?tag=sme_nashville-20&ie=UTF8&linkCode=as2&ascsubtag=c4e57550be9f9a49b6741f5d6ef1a367&ref=dmm_acq_soc_us_u_lfire_lp_x_c4e57550be9f9a49b6741f5d6ef1a367",
    "apple" : "https://music.apple.com/us/album/1721054919?ls=1&app=music&at=1001lLvb&ct=LFV_c4e57550be9f9a49b6741f5d6ef1a367&itscg=30440&itsct=catchall_p2&lId=29137686&cId=none&sr=2&src=Linkfire",
    "spotify" : "https://open.spotify.com/prerelease/4cQc1mgbceTzqTpl7P9t3P?si=d363d162545544ac&go=1",
    "youtube" : "https://music.youtube.com/playlist?list=OLAK5uy_nCyPZKsCe3RkqpZBltYmeVdcl7yK_aLpo&feature=shared&src=Linkfire&lId=cb5c7d60-8090-4196-834f-92f88f5f694c&cId=d3d58fd7-4c47-11e6-9fd0-066c3e7a8751", 
  },
  {
    "name" : "What She Sees In Me",
    "image" : whatSheSeesInMeImg,
    "pivot": "",
    "amazon" : "https://music.amazon.com/albums/B0CQ423V7T?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_QycF6R6J39nKsL0rHHiGAlKS8&trackAsin=B0CQ3Y1TFB",
    "apple" : "https://music.apple.com/us/album/what-she-sees-in-me/1721054919?i=1721055322",
    "spotify" : "https://open.spotify.com/track/4H1Op2dTXFN3HNUKEOK9HF?si=75287bdd4b694a29",
    "youtube" : "https://music.youtube.com/watch?v=N0yiX9FeMCQ&si=CjYduo4Z59ahzV2L", 
  },
  {
    "name" : "Double Down",
    "image" : doubleDownImg,
    "pivot": "",
    "amazon" : "https://music.amazon.com/albums/B0CQ423V7T?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_fn7xQemnEPRwiBK4GGLqumhZx&trackAsin=B0CQ3YDNVG",
    "apple" : "https://music.apple.com/us/album/double-down/1721054919?i=1721055326",
    "spotify" : "https://open.spotify.com/track/7LvbLxitSFgQYfrXResa6k?si=6f74e2995ad3452d",
    "youtube" : "https://music.youtube.com/watch?v=dq6aB1TAUig&si=GTCF_QBwBc1MrMwg", 
  },
  // {
  //   "name" : "Right Now",
  //   "image" : rightNowImg,
  //   "amazon" : "https://amazon.com/music/player/albums/B0CQ423V7T?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_RTK6rjD9QSDs8tBreqB2wDZZE&trackAsin=B0CQ42CVFC",
  //   "apple" : "https://music.apple.com/us/album/right-now/1721054919?i=1721055342",
  //   "spotify" : "https://open.spotify.com/track/5HS8aICH7tjYXSBxzjE9PU?si=9a3eef06788c4a72",
  //   "youtube" : "https://music.youtube.com/watch?v=_hc-Yha8yFw&si=UhFKaCX6zWft02aC", 
  // },
  // {
  //   "name" : "Looking For You",
  //   "image" : lookingForYouImg,
  //   "amazon" : "https://music.amazon.com/albums/B0BRTBGCFD?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_wOlbbuW062xSei0kvmkpCwYaS&trackAsin=B0BRTBRJC8",
  //   "apple" : "https://music.apple.com/us/album/looking-for-you/1659744005?i=1659744006",
  //   "spotify" : "https://open.spotify.com/track/67IfTiMwPNN0wQE7Scfzu9?si=6S2YEG9FT4ew8_yFnYq-Ug&context=spotify%3Asearch%3Alooking%2Bfor%2B",
  //   "youtube" : "https://music.youtube.com/watch?v=q3I81BgJ3BI", 
  // },
  // {
  //   "name" : "Famous Friends",
  //   "image" : famousFriendsImg,
  //   "amazon" : "https://music.amazon.com/albums/B09Y8CCG52?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_O5HFB3EsRAAvWqKbaVvk3d8Av&trackAsin=B09Y7S3B82",
  //   "apple" : "https://music.apple.com/us/album/famous-friends/1540145432?i=1540145776",
  //   "spotify" : "https://open.spotify.com/track/4iXDn9pu5Q9sxv45vE8Lak?si=HQ7P9DH8Rre7-3zvNPTAwg&context=spotify%3Asearch%3Afamous%2Bfriends",
  //   "youtube" : "https://music.youtube.com/watch?v=AO-m-y02JIE", 
  // },
  // {
  //   "name" : "I'm Comin' Over",
  //   "image" : imCominOverImg,
  //   "amazon" : "https://music.amazon.com/albums/B016P0C6IM?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_M1CMUhavnHx7QSjoJNNuunrYa&trackAsin=B016P0C94I",
  //   "apple" : "https://music.apple.com/us/album/im-comin-over/1049067271?i=1049067273",
  //   "spotify" : "https://open.spotify.com/track/0H04yVa3DJxoXbLBpAb7iV?si=qxb8NpDORMutVjIYbrEBcA&context=spotify%3Asearch%3Ai%25E2%2580%2599m%2Bcoming%2Bover&nd=1",
  //   "youtube" : "https://music.youtube.com/watch?v=tw7ORDwXGRw", 
  // },
];  
