import { Box, Button, Flex, Divider, Text } from "@chakra-ui/react";
import { CY_BROWN, externalNavigate } from "../common/utils";

const TourCard = ({date, title, venue, url, offers }) => {
  
  const OFFER_TYPE_TICKET = "Tickets";
  const OFFER_STATUS_AVAILABLE = "available";
  const dateOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  }

  // console.log('Processing event @ ', venue.name );
  // console.log('event url: ', url);

  const localDate = new Date(date);

  const offer = offers.find(offer => 
    (offer.type === OFFER_TYPE_TICKET) && (offer.status === OFFER_STATUS_AVAILABLE));
  // console.log('first available ticket offer: ', JSON.stringify(offer));
  const ticketLink =  offer?.url;
  // console.log('ticket link - ', ticketLink);

  return (
      <Flex 
            w='90%' 
            flexWrap='wrap' 
            fontWeight='light' 
            justifyContent='space-evenly'
      >
        <Box w='20%'  >
          <Text as='b'>
          {localDate.toLocaleDateString("en-US", dateOptions)}
          </Text>
        </Box>
        <Box w='60%' minWidth='10em' justifyContent='center' >
          <Text as='b' h='1.5em' onClick={() => externalNavigate(url, true)}>
            {title.length > 0 ? title : venue.name }
          </Text>
          <Text h='2em'>
            {venue.location}
          </Text>
        </Box>
        <Box w='6em' h='2.5em' justifyContent='center'>
          <Button borderRadius='6em' 
                  bg='white'
                  color={CY_BROWN}
                  fontSize='0.9rem'
                  w='6em'
                  h='2em'
                  isDisabled={!ticketLink}
                  onClick={() => externalNavigate(ticketLink, true)}
          >
            tickets 
          </Button>
        </Box>
        <Divider width='95%' />
      </Flex>
  )
}

export default TourCard;