import { Box, Center, HStack, Spacer, Stack, Link, Text } from "@chakra-ui/react";
import { CY_BROWN, CY_GRAY_TEXT } from "../common/utils";
import SocialStack from './../common/SocialStack';
import { CONTACT_US_ADDR } from "../data/links";

const PageFooter = ({ handlePrivacyClick, handleTermsOfUseClick, handleContactUs }) => {

  return (
    <Stack bg={CY_BROWN} color='white' >
        <Box h='4em' />
        <SocialStack bgColor="" color='white' />
        <Center fontSize='8vw' fontWeight='light'>
          CHRIS YOUNG 
        </Center>
      <Center color={CY_GRAY_TEXT} fontSize='1.7vw'>
        Copyright 2023 CHRIS YOUNG  ALL RIGHTS RESERVED
        </Center>
      <HStack  color={CY_GRAY_TEXT} fontSize='1.5vw' justifyContent='center'>
        <Text className="local-link" onClick={handleTermsOfUseClick}>TERMS OF USE</Text>
        <Text>-</Text>
        <Text className="local-link" onClick={handlePrivacyClick}>PRIVACY POLICY</Text>
        <Text>-</Text>
        <Text className="local-link" onClick={handleContactUs}>CONTACT US</Text>
      </HStack>      
      <Box h='4em' />
  </Stack>
  )
}

export default PageFooter;
