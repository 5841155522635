import { Button, Text, Flex, Stack } from "@chakra-ui/react";
import tourImg from '../images/Chris_Young_2023_Press_Photo.png';
import TourCard from "./TourCard";
import { CY_BROWN, MAX_TOUR_DATES, externalNavigate } from "../common/utils";
import TopText from "../common/TopText";
import { BANDSINTOWN_URL, ALL_EVENTS_LINK } from "../data/links";
import axios from "axios";
import { useEffect, useState } from "react";

const TourPage = () => {

  const [ events, setEvents ] = useState([]);
  const [ error, setError ] = useState();

  const getEvents = () => {
    return axios
      .get(BANDSINTOWN_URL)
      .then((res) => {
        setEvents(res.data);
        setError(null);
        // console.log("returned data: ", res.data);
      })
      .catch((err) => {
        console.error("Error getting tour data: ", err);
        setError(err);
      });
  };

  useEffect(() => {
    getEvents();
  }, []);


  return (
    <Flex bgImage={tourImg} justifyContent='center'
        id='TOUR' w='100%' bgSize='contain' bgPosition='50% 5em' bgRepeat="no-repeat" bgColor={'#070707'}
        >  
      <Flex flexDirection='column' h='100%' >
        <TopText text='TOUR' height='4em' width='20em' color='white'/>    
        {/* <Center color='white' fontSize='0.75rem' flexWrap='wrap'>{JSON.stringify(events)}</Center> */}
        <Flex 
          flexWrap='wrap' 
          color='white' 
          gap={3} 
          width='80%' 
          justifyContent='center'
          paddingBottom={'3em'}
        >
          {events && events.map((tourDate, index) => {
            return <TourCard 
                    key={index}
                    id={index}
                    date={tourDate.datetime}
                    title={tourDate.title} 
                    venue={tourDate.venue} 
                    url={tourDate.url}
                    offers={tourDate.offers} />
            })
          }
          {error && <Stack h='10em' wrap="column">
              <Text>Tour dates for Chris are not available at this time. </Text>
              <Text>Please check back later!</Text>
            </Stack>}
        </Flex>
    </Flex>

  </Flex>

  )
}

export default TourPage;
